















































































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

import { Camera } from "@/models/project/camera";
import TextInput from "@components/text/TextInput.vue";
import Checkbox from "@components/text/Checkbox.vue";
import DetailsPanel from "../DetailsPanel.vue";

const deviceConfig = require("../../../../device.config.json");

@Component({
  components: {
    TextInput,
    Checkbox,
    DetailsPanel,
  },
})
export default class CameraOptions extends Vue {
  @Prop() camera!: Camera;

  get options(): any {
    return this.camera.options != null ? this.camera.options : {};
  }

  get config(): any {
    return deviceConfig[this.camera.type];
  }

  setOptions(options: any): void {
    console.log("TEST");
    Vue.set(this.camera, "options", { ...this.options, ...options });
  }

  getImgUrl(): string | undefined {
    if ((this.camera as any).type != undefined) {
      return require(`../../../assets/devices/${this.camera.type}.webp`);
    }
  }
}
