




























































































































































































































































































































































































import TextButton from "@components/button/TextButton.vue";
import IconButton from "@components/button/IconButton.vue";
import TextInput from "@components/text/TextInput.vue";
import FurtherInfoAlert from "./FurtherInfoAlert.vue";
import DialogTemplate from "@components/dialog/DialogTemplate.vue";
import { Project } from "@/models/project/project";
import Vue from "vue";

import { Component, Watch, Prop } from "vue-property-decorator";
import {
  getStorage,
  ref,
  uploadString,
  getDownloadURL,
} from "firebase/storage";

import Checkbox from "@components/text/Checkbox.vue";

@Component({
  components: {
    DialogTemplate,
    TextButton,
    IconButton,
    TextInput,
    Checkbox,
    FurtherInfoAlert,
  },
})
export default class ExportDialog extends Vue {
  deviceConfig = require("../../../device.config.json");
  snapshot: any = null;
  changeComment = "";
  fileHandle: Blob | null = null;
  productInfo = {
    alinoDetect: false,
    alinoTower: false,
    alinoCam: false,
    hintsAlinoDetect: false,
    hintsAlinoTower: false,
    hintsAlinoCam: false,
  };
  energyAvailable = -1;
  copiedUrl = false;
  fileSource: Blob | null = null;
  title = "Project";
  noHeader = false;
  loadingUpload = 0;
  uploadFile = true;
  selectedFile: Blob | null = null;
  myFactoryId = "";

  dialog = false;
  @Prop() project!: Project;
  @Prop() hasValidArming!: boolean;
  finalPlan = false;

  get windowRatio(): number {
    return window.innerWidth / window.innerHeight;
  }

  get projectDevices(): Array<any> {
    if (this.project == null || this.project.cameras == null) return [];
    const deviceTypes = this.project.cameras.map((device) => {
      return device.type;
    });
    console.log(deviceTypes, this.deviceConfig);

    return [...new Set(deviceTypes)].map((type) => {
      return {
        name: this.deviceConfig[type].name,
        product: this.deviceConfig[type].product,
        descr: this.deviceConfig[type].descr,
        color: this.deviceConfig[type].color,
        id: type,
        count: deviceTypes.filter((x) => x === type).length,
      };
    });
  }

  get projectAdditionals(): any {
    if (this.project == null || this.project.cameras == null) return false;
    var optionsObj = this.project.cameras
      .map((cam) => {
        return { ...cam.options };
      })
      .filter(Boolean);

    const mountings = optionsObj.filter((option) =>
      Object.keys(option).includes("mount") ? option.mount : null
    );
    const standalone = optionsObj.filter((option) =>
      Object.keys(option).includes("standalone") ? option.standalone : null
    );
    let chips = optionsObj.filter((option) => option.chips != null);
    if (chips != null && chips.length > 0)
      chips = chips.reduce((a, b) => parseInt(a.chips) + parseInt(b.chips));

    return { mountings, standalone, chips };
  }

  @Watch("dialog")
  onDialog(): void {
    if (this.dialog == false)
      setTimeout(() => {
        this.snapshot = null;
        this.uploadFile = false;
        this.loadingUpload = 0;
        this.energyAvailable = -1;
        this.changeComment = "";
        this.fileHandle = null;

        this.productInfo = {
          alinoDetect: false,
          alinoTower: false,
          alinoCam: false,
          hintsAlinoDetect: false,
          hintsAlinoTower: false,
          hintsAlinoCam: false,
        };

        const dialogScrollElement = document.getElementById("dialog-content");
        if (dialogScrollElement != null) dialogScrollElement.scrollTop = 0;
      }, 250);
  }

  upload(file: Blob): void {
    this.selectedFile = file;
  }

  async save(): Promise<void> {
    this.loadingUpload = 1;

    var projectHandle: Project = this.project as Project;
    if (projectHandle == null) return;

    projectHandle.info.powerGrid = this.energyAvailable as number;

    if (this.finalPlan == true && this.fileHandle != null) {
      const offerLink = await this.$firebase.uploadFile(
        this.$route.params.projectId,
        "-offer-signed",
        this.fileHandle as Blob
      );
      this.project!.files!.offer = offerLink;
    }

    // projectHandle.internalId = this.myFactoryId;

    await this.$project.updateProject(projectHandle as Project);

    setTimeout(async () => {
      console.log(this.snapshot.stage.scale());
      const result = await this.$pdf.createPlanDocumentation(
        projectHandle as Project,
        this.snapshot,
        this.projectDevices,
        this.projectAdditionals,
        this.productInfo,
        this.finalPlan
      );
      console.log(result);
      this.loadingUpload = 2;

      const storage = getStorage();
      const fileName = `${this.project.id}/plan-${Date.now()}.pdf`;
      const storageRef = ref(storage, fileName);

      try {
        await uploadString(storageRef, result, "data_url");

        this.loadingUpload = 3;
        const shareableUrl = await getDownloadURL(ref(storage, fileName));

        if (shareableUrl == null) throw new Error();
        if (this.finalPlan == true) {
          this.$mail.finishPlanningMail(
            this.$firebase.auth.mail as string,
            this.changeComment,
            this.project as Project,
            document.location.toString(),
            shareableUrl
          );

          // await this.$mail.finishPlanningMail(projectHandle.statusLog[0].mail, this.changeComment, document.location.toString(), shareableUrl, projectHandle as Project)
          await this.$project.updateProjectStatus(
            projectHandle.id as string,
            7,
            this.changeComment,
            shareableUrl
          );
        } else {
          this.$project.updateProjectStatus(
            projectHandle.id as string,
            3,
            this.changeComment,
            shareableUrl
          );
        }

        if (this.finalPlan == false) {
          var a = document.createElement("a");
          a.href = result;
          a.download = `${projectHandle.name}-projektierungsentwurf.pdf`;
          a.click(); //Downloaded file
          a.remove();
        } else {
          return this.$router.push({ name: "overview" });
        }

        this.dialog = false;
        setTimeout(() => {
          this.snapshot = null;
          this.loadingUpload = 0;
        }, 250);
      } catch (error) {
        this.dialog = false;
        this.snapshot = null;
        this.loadingUpload = 0;
        console.log(error);
        this.$toast.error(
          "Projektierung konnte nicht erstellt bzw. hochgeladen werden."
        );
      }

      // })
    }, 1000);
  }

  close(): void {
    this.dialog = false;
    this.loadingUpload = 0;
  }

  open(finalPlan: boolean): void {
    this.finalPlan = finalPlan;
    this.dialog = true;
  }
}
